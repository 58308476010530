import request from "@/utils/request";

// 获取积分记录列表
export function IntegralRecord(params){
    return request({
        url:'/admin/base/goldRecord',
        method: 'get',
        params
        }
    )
}